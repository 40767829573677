<template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Venue Subscribers</div>
            <div class="subtitle-1 font-weight-light">A list of Venues</div>
          </template>
          <v-card-text>
            <v-row class="mx-0" align="center" justify="center" justify-md="start">
              <v-col cols="12" md="8" lg="5" class="px-0">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line outlined
                  hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="8" lg="7" class="px-0" id="email-invite-button-container">
                <v-btn type="submit" class="px-12 mb-3 mx-auto" color="secondary" tile depressed bottom
                  @click="invitationDialog = true">Send Invitation
                </v-btn>
                <v-dialog v-model="invitationDialog" persistent max-width="600px">
                  <v-card class="mx-auto" max-width="600px" outlined>
                    <v-row class="mx-0" align="center" justify="center" justify-md="start">
                      <v-col cols="20" md="20" lg="20" class="px-0">
                        <div class="venue-invite-popup-header">Invite Venue Subscriber</div>
                      </v-col>
                      <v-col cols="6" md="6" lg="6" class="px-0"
                        style="text-align: right;padding-top: 30px;margin-left: 25px">
                        <v-btn icon color="grey lighten-1" @click="invitationDialog = false">
                          <v-icon class="dialog-close-icon">mdi-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <div style="margin-left: 30px;margin-right: 30px;margin-top: 25px">
                      <v-text-field v-model="organization" label="Organization" single-line outlined hide-details
                        class="invite-popup-input" :error-messages="errorOrg"></v-text-field>
                      <v-text-field v-model="name" label="Name" single-line outlined hide-details
                        class="invite-popup-input" :error-messages="errorName"></v-text-field>
                      <v-text-field v-model="email" label="Email" single-line outlined hide-details
                        class="invite-popup-input" :error-messages="errorEmail"></v-text-field>
                      <!--                      <v-text-field v-model="city" label="City" single-line outlined-->
                      <!--                                    hide-details class="invite-popup-input" :error-messages="errorCity"></v-text-field>-->
                      <CityTextField v-model="city" :error-messages="errorCity" />
                      <v-select type="select" ref="key" v-model="type" label="Type" item-text="name" item-value="value"
                        outlined :items="types" class="invite-popup-input" :error-messages="errorType">
                      </v-select>
                      <div style="text-align: center;margin-bottom: 30px">
                        <v-btn type="submit" class="px-12 mb-3 mx-auto" color="secondary" tile depressed bottom
                          @click="inviteFn" style="width: 80%;" :loading="sendingInvitation">Invite
                        </v-btn>
                      </div>
                    </div>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-data-table :headers="headers" :search="search" :items="items" :loading="isLoading"
                  class="venue-data-table">
                  <template v-slot:item.email="{ item }">
                    <v-tooltip bottom v-if="item.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ item.name }}</b> via
                        <b>
                          <i>{{ item.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.website="{ item }">
                    <v-tooltip bottom v-if="item.website">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left website-url" v-on="on">
                          <a :href="validateUrl(item.website)" target="_blank">{{ validateUrl(item.website) }}</a>
                        </span>
                      </template>
                      <span>
                        Visit
                        <b>
                          <i>{{ validateUrl(item.website) }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.city.city="{ item }">
                    <h4 v-if="item.city.city !== undefined">{{ item.city.city }}</h4>
                    <v-dialog
                      v-if="item.city.city === undefined || item.city.city === null || item.city.city.length < 1"
                      v-model="item.isCityModal" :key="item._id" persistent max-width="400">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon color="grey lighten">
                          <font-awesome-icon :icon="['fas', 'pen-alt']" />
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <h3 class="title text-start">
                            Assigning '{{ item.name }}' to a city.
                          </h3>
                        </v-card-title>
                        <v-card-text>
                          <p class="subtitle-2 text-left">
                            Please choose a city from below dropdown and press submit.
                          </p>
                          <v-autocomplete :items="cities" v-model="selectedCity" item-text="city" outlined required
                            return-object hide-details="auto" :label="`Search for Cities`"></v-autocomplete>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="secondary" text @click="item.isCityModal = false">Cancel
                          </v-btn>
                          <v-btn color="success" text @click="updateCity(item)">Update
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <v-dialog v-model="item.isDeleteModal" :key="item._id" persistent max-width="400">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon color="red">
                          <font-awesome-icon :icon="['fas', 'trash-alt']" />
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <h3 class="title text-start">
                            Are you sure you want to delete '{{ item.name }}'?
                          </h3>
                        </v-card-title>
                        <v-card-text>
                          <p class="subtitle-2 text-left">
                            You will be deleting all the data associated with
                            this Venue Subscriber.
                          </p>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="secondary" text @click="item.isDeleteModal = false">No, Cancel
                          </v-btn>
                          <v-btn color="error" text @click="deleteFn(item)">Yes, Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <v-btn icon color="grey lighten-1" @click="RouteFn(item._id, item.email)">
                      <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </v-btn>
                  </template>
                  <template v-slot:[`item.booking`]="{ item }">
                    <v-btn icon color="grey lighten-1" @click="bookEvent(item)">
                      <font-awesome-icon :icon="['fas', 'calendar']" />
                    </v-btn>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    <!-- <span class="subtitle-1 text-left" v-on="on"> -->
                    {{ DateFormatYYMMDD(item.created_at) }}
                    <!-- </span> -->
                  </template>
                  <template v-slot:item.isWTTCCertificate="{ item }">
                    <v-dialog v-model="item.isModal" :key="item._id" persistent max-width="400">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon>
                          <font-awesome-icon :icon="['fas', 'file-medical']"
                            :color="item.isWTTCCertificate ? 'green' : 'grey'" />
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title v-if="!item.isWTTCCertificate">
                          <h3 class="title text-start text-break">
                            Are you sure this venue '{{ item.venueName }}' has
                            The WTTC certificate?
                          </h3>
                        </v-card-title>
                        <v-card-title v-else>
                          <h3 class="title text-start text-break">
                            Are you sure you want to revoke the '{{
                              item.venueName
                            }}''s WTTC certificate?
                          </h3>
                        </v-card-title>
                        <v-card-text v-if="!item.isWTTCCertificate">
                          <p class="subtitle-2 text-left">
                            You will be updating the venue as WTTC certified.
                            Are you sure?
                          </p>
                        </v-card-text>
                        <v-card-text v-else>
                          <p class="subtitle-2 text-left">
                            You will be updating the venue as not WTTC
                            certified. Are you sure?
                          </p>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="black" text @click="item.isModal = false">No, Cancel
                          </v-btn>
                          <v-btn v-if="!item.isWTTCCertificate" color="green" text @click="healthBadgeFn(item)">Yes,
                            Update
                          </v-btn>
                          <v-btn v-else color="red" text @click="healthBadgeFn(item)">Yes, Revoke
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <EventRequestEditModal b_version="new" :eventItem="requestedItem" ref="eventRequestEditModal" @reload="reloadfn" />
  </v-container>
</template>

<script>
import venueApicalls from "../../../services/venue.service";
import DateFormatYYMMDD from "../../../mixin/DateFormatYYMMDD";
// import cityServices from "../../../services/city.service";
import venueServices from "../../../services/venue.service";
import cityServices from "../../../services/citySubscribers.service";
import CityTextField from "../../Common/CityTextField";
import EventRequestEditModal from "../Bookings/EventRequestEditModal.vue";

export default {
  name: "Venue",
  components: { CityTextField, EventRequestEditModal },
  data() {
    return {
      search: "",
      isLoading: false,
      deleteDialog: false,
      items: [],
      invitationDialog: false,
      sendingInvitation: false,
      types: [],
      type: null,
      organization: null,
      name: null,
      city: null,
      cities: [],
      selectedCity: '',
      email: null,
      errorName: null,
      errorOrg: null,
      errorCity: null,
      errorEmail: null,
      errorType: null,
      staticErrorMessages: {
        org: {
          isEmpty: "Organization should not be empty",
        },
        name: {
          isEmpty: "Name should not be empty",
        },
        city: {
          isEmpty: "Please select city",
        },
        email: {
          isEmpty: "Email should not be empty",
        },
        type: {
          isEmpty: "Venue type is required",
        },
      },
      bookingVenue: null,
      requestedItem: [],
    };
  },
  computed: {
    headers() {
      let adminType = JSON.parse(localStorage.getItem("admin")).admin.adminType;
      if (adminType == "master" || adminType == "superAdmin") {
        return [
          {
            sortable: true,
            text: "Venue",
            value: "venueName",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Venue Rep.",
            value: "name",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Email",
            value: "email",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Website",
            value: "website",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Joined Date",
            value: "created_at",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "City",
            value: "city.city",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Delete",
            value: "delete",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Bookings",
            value: "booking",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Health Badge",
            value: "isWTTCCertificate",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "More",
            value: "status",
            class: "primary--text title",
          },
        ];
      } else {
        return [
          {
            sortable: true,
            text: "Venue",
            value: "venueName",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Venue Rep.",
            value: "name",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Email",
            value: "email",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Website",
            value: "website",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "Joined date",
            value: "created_at",
            class: "primary--text title",
          },
          {
            sortable: true,
            text: "City",
            value: "city.city",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Bookings",
            value: "booking",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "Health Badge",
            value: "isWTTCCertificate",
            class: "primary--text title",
          },
          {
            sortable: false,
            text: "More",
            value: "status",
            class: "primary--text title",
          },
        ];
      }
    },
    computedCities() {
      // console.log(this.computedShortListedCities);

      let cities = this.cities.filter(
        (x) => !this.computedShortListedCities.includes(x._id)
      );

      let cityExc = cities.map((x) => {
        return {
          ...x,
          city: this.capitalize(x.city),
        };
      });

      return cityExc;
    },
  },
  mounted() {
    this.isLoading = true;
    venueApicalls.getAllVenue().then((res) => {
      console.log(res.data.content.data)
      this.items = res.data.content.data.map((x) => {
        if (x.city.city === undefined || x.city.city === null || x.city.city.length < 1 && x.city.country === undefined && x.city.postalCode === undefined && x.city.street === undefined) {
          console.log(x)
          x.city.city = null;
        }
        return {
          ...x,
          isDeleteModal: false,
          isCityModal: false,
        };
      });
      this.isLoading = false;
    });
    // .catch(err => {
    //   // console.log(err);
    // });
    console.log('========')
    cityServices.getAllCityName().then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].city = res.data[i].city.toUpperCase();
      }
      this.cities = res.data;
      this.overlay = false;
    });
    this.types = ['Basic', 'Advance'];
  },
  methods: {
    DateFormatYYMMDD,
    deleteFn(val) {
      this.isLoading = true;
      let payload = {
        _id: val._id,
        // isAdvance: !val.isAdvance
      };
      // console.log("venue  ", payload);
      venueApicalls.setDelete(payload).then((res) => {
        if (res.data.status == "success") {
          let payload2 = {
            snackBarText: "Venue Subscriber has been Deleted Successfully",
            snackBarColor: "secondary",
          };
          this.deleteDialog = false;
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          venueApicalls.getAllVenue().then((res) => {
            this.items = res.data.content.data;
          });
        }
        this.isLoading = false;
      });
    },
    healthBadgeFn(val) {
      let payload = {
        _id: val._id,
        // isWTTCCertificate: val.isWTTCCertificate
      };
      venueApicalls.SetWTTCCertificate(payload).then((res) => {
        // console.log(res.data);
        if (res.data.success) {
          let payload2 = {
            snackBarText:
              "Venue Subscriber's  WTTC Certificate Status has been updated Successfully",
            snackBarColor: "secondary",
          };
          // this.deleteDialog = false;
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          venueApicalls.getAllVenue().then((res) => {
            this.items = res.data.content.data;
          });
        }
      });
      // console.log(payload);
    },
    RouteFn(id, email) {
      this.$store.dispatch("setUserEmail", email);
      localStorage.setItem("setUserEmail", email);
      this.$router.push(`/venue-subscribers-view/${id}`);
      // console.log(id);
    },
    validateUrl(url) {
      if (!url) return;
      let newUrl = window.decodeURIComponent(url);
      newUrl = newUrl.trim().replace(/\s/g, "");
      if (/^(:\/\/)/.test(newUrl)) {
        return `https${newUrl}`;
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `https://${newUrl}`;
      }
      return newUrl;
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    inviteFn() {
      if (this.email && this.name && this.organization && this.city && this.type) {
        this.sendingInvitation = true;
        let payload = {
          Name: this.name,
          Organization: this.organization,
          City: this.city,
          Email: this.email,
          Type: this.type,
          Option: 2
        };
        venueServices.inviteVenue(payload).then((res) => {
          if (res.data.message === "success") {
            this.sendingInvitation = false;
            let payload2 = {
              snackBarText:
                "Venue has been invited!",
              snackbarColor: "success",
            };
            this.$store.dispatch("notify/setSnackbarMessage", payload2);
            this.name = null;
            this.errorName = null;
            this.organization = null;
            this.errorOrg = null;
            this.city = null;
            this.errorCity = null;
            this.email = null;
            this.errorEmail = null;
            this.type = null;
            this.errorType = null;
            this.invitationDialog = false;
          } else {
            this.sendingInvitation = false;
            this.$store.dispatch("notify/setSnackbarMessage", {
              snackBarText:
                "This email has already been registered!",
              snackbarColor: "error",
            });
          }
        });
      } else {
        if (!this.name) {
          this.errorName = this.staticErrorMessages.name.isEmpty;
        }
        if (!this.organization) {
          this.errorOrg = this.staticErrorMessages.org.isEmpty;
        }
        if (!this.email) {
          this.errorEmail = this.staticErrorMessages.email.isEmpty;
        }
        if (!this.city) {
          this.errorCity = this.staticErrorMessages.city.isEmpty;
        }
        if (!this.type) {
          this.errorType = this.staticErrorMessages.type.isEmpty;
        }
      }
    },
    updateCity(venue) {
      venueServices.UpdateVenueCity({
        city: this.capitalize(this.selectedCity.city.toLowerCase()),
        venue: venue._id,
        cityId: this.selectedCity._id
      }).then(() => {
        this.$store.dispatch("notify/setSnackbarMessage", {
          snackBarText: "Venue City has been Updated Successfully",
          snackBarColor: "secondary",
        });
        venueApicalls.getAllVenue().then((res) => {
          this.items = res.data.content.data;
        });
      });
    },
    bookEvent(venue) {
      console.log(venue)
      this.bookingVenue = {
        _id: null,
        logo: null,
        banner: null,
        name: venue.name,
        email: venue.email,
        description: '',
        startDate: '',
        endDate: '',
        eventName: '',
        eventUrl: '',
        venueAddress: '',
        venueName: venue.venueName,
        partnerHotels: [{
          name: '',
          url: '',
          rent: '',
          breakfast: false,
          currency: "€",
        }],
        notes: '',
        userType: venue.userType,
        createdBy: venue._id,
        map: '',
        allez: '',
        eventId: ''
      }
      this.requestedItem = this.bookingVenue;
      this.$refs.eventRequestEditModal.open();
    },
    reloadfn() {
      location.reload();
    }
  },
};
</script>
<style scoped>
.venue-data-table>>>td.text-start {
  max-width: 120px;
}

.venue-invite-popup-header {
  font-weight: bold;
  font-size: 20px;
  padding-left: 30px;
  padding-top: 15px;
}

.invite-popup-input {
  margin-bottom: 10px;
}

#email-invite-button-container {
  text-align: right;
}
</style>
