<template>
    <v-dialog v-model="eventRequestEdit" max-width="600" scrollable>

        <v-card>
            <v-card-title class="px-2 px-sm-6">
                <v-row class="pb-3">
                    <v-col cols="8" lg="10" class="d-flex flex-column justify-center align-start py-0">
                        <h2 class="title text-start text-break">
                            {{b_version !== null ? 'Create Booking For '+venueName:'Edit Request'}}
                        </h2>
                    </v-col>
                    <v-col cols="4" lg="2" class="d-flex flex-column justify-center align-end py-0">
                        <v-btn icon color="grey lighten-1" @click="eventRequestEdit = false">
                            <v-icon class="dialog-close-icon">mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>

                <div style="margin-bottom: 25px">
                    <v-col>

                        <h1 class="display-1 text-left px-2 px-sm-6 py-4 pb-3">
                            Event Booking
                        </h1>
                        <v-form class="px-2 px-sm-6 py-4" ref="form" lazy-validation>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-file-input label="Upload Logo*" placeholder="Choose File*" prepend-icon
                                        append-icon="mdi-paperclip" clear-icon="mdi-close" v-model="logo"
                                        @change="fileChanged" ref="fileClearUpload" truncate-length="14"
                                        :error-messages="errorFile" multiple clearable show-size outlined required
                                        :key="fileUploadKey" accept="image/*" hide-details="auto"
                                        class="pb-2 pt-4"></v-file-input>
                                    <v-img v-if="imagePreview" :src="imagePreview" width="150px" height="100px"
                                        class="mb-4" contain></v-img>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col cols="11" class="py-0">
                                    <v-file-input label="Upload Banner*(Preferred size: 1000 x 200)"
                                        placeholder="Choose File*" prepend-icon append-icon="mdi-paperclip"
                                        clear-icon="mdi-close" v-model="banner" @change="bannerFileChanged"
                                        ref="fileClearUpload" truncate-length="14" :error-messages="errorBannerFile"
                                        multiple clearable show-size outlined required :key="fileUploadKey"
                                        accept="image/*" hide-details="auto" class="py-8"></v-file-input>
                                    <v-img v-if="bannerImagePreview" :src="bannerImagePreview" width="150px"
                                        height="100px" class="mb-4" contain></v-img>

                                </v-col>
                                <v-col cols="1" class="py-0 align-center justify-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <font-awesome-icon style="float: right ;width: 18px;height: 18px;"
                                                class="info-icon mt-12" :icon="['fas', 'info-circle']" color="gray"
                                                size="xs" v-on="on" />
                                        </template>
                                        <span class="info-tooltip-text">Include Banner in Rectangluar Format<br>
                                            Minimum Resolution 1000x200 Pixel (WidthxHeight)<br>
                                            Maximum File Size 1MB</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Name" v-model="name" outlined required
                                        :error-messages="errorName"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Email" v-model="email" outlined required
                                        :error-messages="errorEmail"></v-text-field>
                                </v-col>
                            </v-row>
                            <!-- <v-row>
                                <v-col cols="6" class="py-0">
                                    <v-menu ref="menucolour" v-model="menucolourOne" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div style="height: 10px;margin-bottom: 7px; border-radius: 3px"
                                                :style="{ backgroundColor: colourOne }"></div>
                                            <v-text-field v-bind="attrs" v-on="on" label="Primary Color"
                                                v-model="colourOne" outlined required :error-messages="errorcolourOne"
                                                prepend-inner-icon="mdi-palette"></v-text-field>
                                        </template>
                                        <v-card>
                                            <v-color-picker v-model="colourOne" hide-canvas></v-color-picker>

                                        </v-card>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-menu ref="menucolour" v-model="menucolourTwo" :close-on-content-click="false"
                                        transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <div style="height: 10px;margin-bottom: 7px; border-radius: 3px"
                                                :style="{ backgroundColor: colourTwo }"></div>
                                            <v-text-field v-bind="attrs" v-on="on" label="Secondary Color"
                                                v-model="colourTwo" outlined required :error-messages="errorcolourTwo"
                                                prepend-inner-icon="mdi-palette"></v-text-field>
                                        </template>
                                        <v-card>
                                            <v-color-picker v-model="colourTwo" hide-canvas></v-color-picker>

                                        </v-card>
                                    </v-menu>
                                </v-col>
                            </v-row> -->
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Name of the Event" v-model="eventName" outlined required
                                        :error-messages="errorEventName"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="URL of the Event" v-model="eventURL" outlined required
                                        :error-messages="errorEventURL"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="12" class="py-0">
                                <v-text-field label="Name of the Venue*" v-model="venueName" outlined required
                                    :error-messages="errorVenueName"></v-text-field>
                            </v-col>
                        </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Address of the Venue" v-model="venueAddress" outlined required
                                        :error-messages="errorVenueAddress"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="6" class="py-0">
                                <v-menu ref="menuStartDate" v-model="menuStartDate" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="290px"
                                    :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="StartDate" label="Start Date*" append-icon="mdi-calendar"
                                            readonly v-bind="attrs" v-on="on" outlined required
                                            :error-messages="errorStartDate"
                                            @click="menuStartDate = true"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="StartDate" no-title scrollable
                                        @input="menuStartDate = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <v-menu ref="menuEndDate" v-model="menuEndDate" :nudge-right="40"
                                    transition="scale-transition" offset-y min-width="290px"
                                    :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field  v-model="EndDate" label="End Date*" append-icon="mdi-calendar"
                                            readonly v-bind="attrs" v-on="on" outlined required
                                            :error-messages="errorEndDate" @click="openEndDatePicker"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="EndDate" no-title scrollable
                                       
                                        :allowed-dates="date => !StartDate || new Date(date) >= new Date(StartDate)"
                                        @input="menuEndDate = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-textarea @keydown.enter="handlePoints" label=" Intro (Max. 500 Characters)*"
                                        v-model="intro" outlined required :error-messages="errorIntro"
                                        :counter="500"></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6" class="py-0 d-flex flex-column">
                                    <h2 class="title text-left pb-3">
                                        Partner Hotels
                                    </h2>
                                </v-col>
                            </v-row>

                            <v-row v-for="(hotel, index) in partnerHotels" :key="index">
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Partner Hotel Name" v-model="hotel.name" outlined
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="12" class="py-0" style="min-height: 86px">
                                    <v-text-field class="my-0" label="Hotel Booking URL" v-model="hotel.url" outlined
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="12" class="py-0" style="min-height: 86px">
                                    <v-row no-gutters>
                                        <v-col cols="8">
                                            <v-text-field class="my-0 fieldBox" label="Hotel Room Rate"
                                                @keypress="[isNumber()]" v-model="hotel.rate" outlined
                                                required></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select class="my-0 options" v-model="hotel.currency"
                                                :items="hotelUnitOptions" outlined></v-select>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="10" class="py-0" style="min-height: 86px">
                                    <v-checkbox class="align-start justify-start my-0 py-0" v-model="hotel.breakfast"
                                        label="Breakfast Included"></v-checkbox>
                                </v-col>
                                <v-col cols="12" md="2" class="py-0" style="min-height: 86px" v-show="index !== 0">
                                    <v-btn text color="primary" class="px-0 mt-n4" @click="removePartnerHotel(index)">
                                        <u>Remove</u>
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <div class="text-left mt-4">
                                <v-btn text color="secondary" class="px-0 mt-n4" @click="addMorePartnerHotel"
                                    :disabled="partnerHotels.length >= 6">
                                    <u>Add More</u>
                                </v-btn>
                            </div>
                            <br /><br />

                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-textarea label="Additional Notes (Max. 500 Characters)*" v-model="notes" outlined
                                        required :error-messages="errorNotes" :counter="500"></v-textarea>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Stay22 Map Link" v-model="mapLink" outlined required
                                        :error-messages="errorMapLink"></v-text-field>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col cols="12" class="py-0">
                                    <v-text-field label="Allez Link" v-model="allezLink" outlined required
                                        :error-messages="errorAllezLink"></v-text-field>
                                </v-col>

                            </v-row>
                            <v-row align-content="center" class="fill-height" justify="center"
                                v-if="submissionStatus !== null">
                                <v-col class="text-subtitle-1 text-center" cols="12">
                                    {{ submissionStatus }}
                                </v-col>
                                <v-col cols="6">
                                    <v-progress-linear color="cyan" indeterminate></v-progress-linear>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-0 d-flex  align-center justify-center">
                                    <v-btn class="align-self-center px-8" color="secondary" @click="submitFn" tile
                                        depressed>
                                        Submit
                                    </v-btn>
                                </v-col>

                            </v-row>
                        </v-form>
                    </v-col>

                </div>

            </v-card-text>

        </v-card>
    </v-dialog>
</template>
<script>

import AdminApiCalls from "../../../services/admin.sevice";
import isNumber from "@/mixin/IsNumber";
export default {
    name: "eventRequestEdit",
    mixins: [isNumber],
    props: {
        eventItem: [],
        b_version:null
    },
    data() {
        return {
            id: null,
            eventRequestEdit: false,
            logo: null,
            name: null,
            email: null,
            intro: null,
            notes: null,
            EndDate: null,
            StartDate: null,
            menuStartDate: false,
            menuEndDate: false,
            menucolourOne: false,
            menucolourTwo: false,
            colourOne: '',
            colourTwo: '',
            imagePreview: null,
            bannerImagePreview: null,
            banner: null,
            eventName: null,
            eventURL: null,
            venueAddress: null,
            venueName:null,
            partnerHotelName: null,
            partnerHotelURL: null,
            errorDate: null,
            errorName: null,
            errorEmail: null,
            errorIntro: null,
            errorNotes: null,
            errorcolourOne: null,
            errorcolourTwo: null,
            errorFile: null,
            errorBannerFile: null,
            errorEventName: null,
            errorEventURL: null,
            errorVenueAddress: null,
            errorVenueName:null,
            errorPatnerHotelName: null,
            errorPartnerHotelURL: null,
            user: null,
            submissionStatus: null,
            userId: null,
            userType: null,
            allezLink: null,
            mapLink: null,
            partnerHotels: [{
                name: '',
                url: '',
                rate: '',
                breakfast: false,
                currency: "€",
            }],
            hotelUnitOptions: ["€", "$"],
           
            EventId: null
        };
    },
    watch: {
        eventItem() {
            this.id = this.eventItem._id;
            this.logo = this.eventItem.logo ? this.eventItem.logo : null;
            this.banner = (this.eventItem.banner && this.eventItem.banner !== "N/A") ? this.eventItem.banner : null;
            this.name = this.eventItem.name || '';
            this.email = this.eventItem.email || '';
            this.intro = this.eventItem.description || '';
            // this.colourOne = this.eventItem.colors[0] || '';
            // this.colourTwo = this.eventItem.colors[1] || '';
            this.StartDate = this.eventItem.startDate || '';
            this.EndDate = this.eventItem.endDate || '';
            this.eventName = this.eventItem.eventName || '';
            this.eventURL = this.eventItem.eventUrl || '';
            this.venueAddress = this.eventItem.venueAddress || '';
            this.venueName =  this.eventItem.venueName || '';
            this.partnerHotels = this.eventItem.partnerHotels.length > 0
                ? this.eventItem.partnerHotels
                : [{
                    name: '',
                    url: '',
                    rent: '',
                    breakfast: false,
                    currency: "€",
                }];
            this.notes = this.eventItem.notes || '';
            this.imagePreview = this.eventItem.logo ? this.eventItem.logo : null;
            this.bannerImagePreview = (this.eventItem.banner && this.eventItem.banner !== "N/A") ? this.eventItem.banner : null;
            this.userType = this.eventItem.userType;
            this.userId = this.eventItem.createdBy;
            this.mapLink = this.eventItem.map || '';
            this.allezLink = this.eventItem.allez || '';
            this.EventId = this.eventItem.eventId || ''
        },
        intro(val) {
            if (val) {
                if (val.length > 500) {
                    this.errorIntro = "Intro must not exceed 500 characters"
                } else if (val.length < 3) {
                    this.errorIntro = "Intro required minimum 3 characters"
                } else {
                    this.errorIntro = null
                }
                this.handlePoints();
            }
        },
        name(value) {
            if (value) {
                this.errorName = null
            }
        },
        email(value) {
            if (!this.validEmail(value)) {
                this.errorEmail = "Please enter a valid email."

            } else {
                this.errorEmail = null
            }
        },
        // colourOne(val) {
        //     if (val) {
        //         this.errorcolourOne = null
        //     }
        // },
        // colourTwo(val) {
        //     if (val) {
        //         this.errorcolourTwo = null
        //     }
        // },
        EndDate(val) {
            if (val) {
                this.errorEndDate = null
            }
        },
        StartDate(val) {
            if (val) {
                this.errorStartDate = null
                
            }
        },
        logo(val) {
            if (val) {
                this.errorFile = null
            }

        },
        // banner(val) {
        //     if (val) {
        //         this.errorBannerFile = null
        //     }
        // },
        eventName(val) {
            if (val) {
                this.errorEventName = null
            }
        },
        eventURL(val) {
            if (val) {
                this.errorEventURL = null
            }
        },
        venueAddress(val) {
            if (val) {
                this.errorVenueAddress = null
            }
        },
        venueName(val){
            if(val){
                this.errorVenueName = null
            }
        },
        partnerHotelName(val) {
            if (val) {
                this.errorPatnerHotelName = null
            }
        },
        partnerHotelURL(val) {
            if (val) {
                this.errorPartnerHotelURL = null
            }
        },

        notes(val) {
            if (val) {
                if (val.length > 500) {
                    this.errorNotes = "Notes must not exceed 500 characters"
                } else if (val.length < 3) {
                    this.errorNotes = "Notes required minimum 3 characters"
                } else {
                    this.errorNotes = null
                }
            }
        },

    },
    async mounted() {
        this.$nextTick(() => {
            this.handlePoints();
        });
    },
    methods: {
        openEndDatePicker() {
            this.menuEndDate = true;
            this.$nextTick(() => {
                if (this.StartDate) {
                    this.$refs.endDatePicker.tableDate = this.StartDate;
                }
            });
        },
        async submitFn() {
            if (this.name && this.email && this.intro && this.notes && this.EndDate && this.StartDate  && this.eventName && this.eventURL
                && this.venueAddress && this.venueName && this.imagePreview) {
                this.submissionStatus = "Uploading Logo";
                const logoUrl = await this.uploadImage();
                this.submissionStatus = "Uploading Banner";
                const bannerUrl = await this.banneruploadImage();
                this.submissionStatus = "Submitting your request";
                const Status = this.mapLink.includes('https') && this.allezLink.includes('https') ? "completed" : "edited";

                let hotels = [];
                for (let i = 0; i < this.partnerHotels.length; i++) {
                    if (this.partnerHotels[i].name.length > 1 && this.partnerHotels[i].url.length > 3 && this.partnerHotels[i].rate.length > 1) {
                    hotels.push(this.partnerHotels[i]);
                    }

                }
                const payload = {
                    id: this.id,
                    logo: logoUrl,
                    banner: bannerUrl,
                    name: this.name,
                    email: this.email,
                    // colors: [this.colourOne, this.colourTwo],
                    eventName: this.eventName,
                    eventUrl: this.eventURL,
                    venueAddress: this.venueAddress,
                    venueName : this.venueName,
                    startDate: this.StartDate,
                    endDate: this.EndDate,
                    description: this.intro,
                    partnerHotels: hotels,
                    notes: this.notes,
                    createdBy: this.userId,
                    userType: this.userType,
                    status: Status,
                    allez: this.allezLink,
                    map: this.mapLink,
                    eventId: this.EventId
                }
                const res = await AdminApiCalls.updateEventBooking(payload);
                this.submissionStatus = null;
                console.log(res)
                if (res.status === 200) {
                    this.eventRequestEdit = false;
                }

                this.$emit("reload")
            } else {
                this.validateForm()
            }
        },
        handlePoints() {
            const texts = this.intro.split('\n');
            let updated = '';

            for (let i = 0; i < texts.length; i++) {
                const line = texts[i].trim();

                if (line) {
                    updated += line;
                }

                if (i < texts.length - 1) {
                    updated += '\n';
                }
            }

            this.intro = updated;
        },
        // Adds a bullet point on pressing Enter
        addBulletPoint() {
            const textarea = this.$refs.introTextarea.$el.querySelector('textarea');
            const cursorPosition = textarea.selectionStart;
            const textBefore = this.intro.substring(0, cursorPosition);
            const textAfter = this.intro.substring(cursorPosition);
            this.intro = `${textBefore}\n• ${textAfter}`;
            this.$nextTick(() => {
                textarea.selectionStart = textarea.selectionEnd = cursorPosition + 3; // Adjust cursor position to be after the new bullet point
            });
        },
        open() {
            this.eventRequestEdit = true;
        },
        fileChanged() {
            console.log(this.user)
            if (this.logo && this.logo.length > 0) {
                const file = this.logo[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePreview = e.target.result;
                };
                reader.readAsDataURL(file);
            } else {
                this.imagePreview = null;
            }
        },
        bannerFileChanged() {
            const maxFileSize = 1 * 1024 * 1024
            const minWidth = 1000
            const minHeight = 200
            if (this.banner && this.banner.length > 0) {
                const file = this.banner[0];
                // Validate file size
                if (file.size > maxFileSize) {
                    this.errorBannerFile = `File size should not exceed ${maxFileSize / 1024 / 1024} MB.`;
                    this.banner = null;
                    this.bannerImagePreview = null;
                    return;
                }
                const reader = new FileReader();
                reader.onload = (e) => {
                    const img = new Image();
                    img.onload = () => {
                        // Validate image dimensions
                        if (img.width < minWidth || img.height < minHeight) {
                            this.errorBannerFile = `Image dimensions should be at least ${minWidth}x${minHeight} pixels.`;
                            this.banner = null;
                            this.bannerImagePreview = null;
                        } else {
                            this.bannerImagePreview = e.target.result;
                        }
                    };

                    img.src = e.target.result;
                };
                reader.readAsDataURL(file);
            } else {
                this.bannerImagePreview = null;
            }
        },
        async uploadImage() {
            const toBase64 = file =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve(reader.result)
                    reader.onerror = error => reject(error)
                });
            if (this.imagePreview && !this.imagePreview.includes('https')) {

                const payloadImg = {
                    Path: 'booking_resources/'+this.userId + this.logo[0].name.toLowerCase().replace(/[^a-zA-Z.]/g, ''),
                    Base64: await toBase64(this.logo[0])
                }
                const imgResponse = await AdminApiCalls.bookingUpload(payloadImg);
                return imgResponse.data.location;
            } else {
                return this.imagePreview
            }
        },
        async banneruploadImage() {
            const toBase64 = file =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve(reader.result)
                    reader.onerror = error => reject(error)
                });
            if (this.bannerImagePreview && !this.bannerImagePreview.includes('https')) {

                const payloadImg = {
                    Path: 'booking_resources/'+this.userId + this.banner[0].name.toLowerCase().replace(/[^a-zA-Z.]/g, ''),
                    Base64: await toBase64(this.banner[0])
                }
                const imgResponse = await AdminApiCalls.bookingUpload(payloadImg);
                return imgResponse.data.location;
            } else {
                return this.bannerImagePreview
            }
        },
        validateForm() {
            if (!this.email) {
                this.errorEmail = "Please fill out this field"
                let payload = {
                    snackBarText: this.errorEmail,
                    snackBarColor: 'error'
                }
                this.$store.dispatch('notify/setSnackbarMessage', payload)
            }
            if (!this.name) {
                this.errorName = "Please fill out this field"
                let payload = {
                    snackBarText: this.errorName,
                    snackBarColor: 'error'
                }
                this.$store.dispatch('notify/setSnackbarMessage', payload)

            }
            if (!this.intro) {
                this.errorIntro = "Please fill out this field"
                let payload = {
                    snackBarText: this.errorIntro,
                    snackBarColor: 'error'
                }
                this.$store.dispatch('notify/setSnackbarMessage', payload)

            }
            if (!this.EndDate) {
                this.errorEndDate = "Please fill out this field"
                let payload = {
                    snackBarText: this.errorEndDate,
                    snackBarColor: 'error'
                }
                this.$store.dispatch('notify/setSnackbarMessage', payload)

            }
            if (!this.StartDate) {
                this.errorStartDate = "Please fill out this field"
                let payload = {
                    snackBarText: this.errorStartDate,
                    snackBarColor: 'error'
                }
                this.$store.dispatch('notify/setSnackbarMessage', payload)

            }
            if (!this.logo.length > 0) {
                this.errorFile = "Please fill out this field"
                let payload = {
                    snackBarText: this.errorFile,
                    snackBarColor: 'error'
                }
                this.$store.dispatch('notify/setSnackbarMessage', payload)

            }
            // if (!this.banner.length > 0) {
            //     this.errorBannerFile = "Please fill out this field"
            //     let payload = {
            //         snackBarText: this.errorBannerFile,
            //         snackBarColor: 'error'
            //     }
            //     this.$store.dispatch('notify/setSnackbarMessage', payload)

            // }
            // if (!this.colourOne) {
            //     this.errorcolourOne = "Please fill out this field"
            //     let payload = {
            //         snackBarText: this.errorcolourOne,
            //         snackBarColor: 'error'
            //     }
            //     this.$store.dispatch('notify/setSnackbarMessage', payload)

            // }
            // if (!this.colourTwo) {
            //     this.errorcolourTwo = "Please fill out this field"
            //     let payload = {
            //         snackBarText: this.errorcolourTwo,
            //         snackBarColor: 'error'
            //     }
            //     this.$store.dispatch('notify/setSnackbarMessage', payload)

            // }
            if (!this.eventName) {
                this.errorEventName = "Please fill out this field"
                let payload = {
                    snackBarText: this.errorEventName,
                    snackBarColor: 'error'
                }
                this.$store.dispatch('notify/setSnackbarMessage', payload)

            }
            if (!this.eventURL) {
                this.errorEventURL = "Please fill out this field"
                let payload = {
                    snackBarText: this.errorEventURL,
                    snackBarColor: 'error'
                }
                this.$store.dispatch('notify/setSnackbarMessage', payload)

            }
            if (!this.venueAddress) {
                this.errorVenueAddress = "Please fill out this field"
                let payload = {
                    snackBarText: this.errorVenueAddress,
                    snackBarColor: 'error'
                }
                this.$store.dispatch('notify/setSnackbarMessage', payload)

            }
            if (!this.venueName) {
                this.errorVenueName = "Please fill out the venue name"
                let payload = {
                    snackBarText: this.errorVenueName,
                    snackBarColor: 'error'
                }
                this.$store.dispatch('setSnackbarMessage', payload)

            }

            if (!this.notes) {
                this.errorNotes = "Please fill out this field"
                let payload = {
                    snackBarText: this.errorNotes,
                    snackBarColor: 'error'
                }
                this.$store.dispatch('notify/setSnackbarMessage', payload)

            }
        },
        addMorePartnerHotel() {
            this.partnerHotels.push({
                name: '',
                url: '',
                rate: '',
                breakfast: false,
                currency: "€",
            });
        },
        removePartnerHotel(index) {
            this.partnerHotels.splice(index, 1);
        },
        validEmail(value) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(value);
        },

    },
};
</script>
<style scoped>
.img-placeholder {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.img-cover-placeholder {
    width: 100%;
    height: auto;
    max-height: 300px;
    border-radius: 5px;
}
.options{
  border-radius: 0px 4px 4px 0px;
}
.fieldBox{
  border-radius: 4px 0px 0px 4px;
}
</style>
<style>
.v-dialog .v-card .v-card__title .v-icon.dialog-close-icon {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 32px;
    height: 32px;
    font-size: 24px;
}
</style>